import React, { useState } from "react";
import { Button } from "@mui/material";
import styles from "./CreateSnapshotBar.module.scss";
import LinearProgress from "@mui/material/LinearProgress";
import { createSnapshot } from "../../utils/api";

const CreateSnapshotBar = () => {
  const [isProcessing, setIsProcessing] = useState(false);

  const onClickHandler = () => {
    setIsProcessing(true);
    createSnapshot().catch((e) => {
      setIsProcessing(false);
    });
  };

  if (isProcessing) {
    return (
      <div className={styles.loadingContainer}>
        <LinearProgress style={{ flex: "1" }} />
        <div style={{ padding: "0 10px" }}>
          Queued. Please check back & refresh this page in 4-5 minutes.
        </div>
        <LinearProgress style={{ flex: "1" }} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Button disabled={isProcessing} onClick={onClickHandler}>
        Create Snapshot
      </Button>
    </div>
  );
};

export default CreateSnapshotBar;
