import styles from "./Profile.module.scss";
import React from "react";

const Metrics = ({ lcp, fcp, tbt, tti }) => {
  [lcp, fcp, tbt, tti] = [lcp, fcp, tbt, tti].map((metric) =>
    metric ? metric.split(/\u00a0/g) : ["N/A", "N/A"]
  );

  return (
    <div className={styles.metricsContainer}>
      <div className={styles.metric}>
        <div className={styles.metricNumber}>
          {lcp[0]}
          <span className={styles.metricUnit}>{lcp[1]}</span>
        </div>
        <div className={styles.metricTitle}>LCP</div>
      </div>
      <div className={styles.metric}>
        <div className={styles.metricNumber}>
          {fcp[0]}
          <span className={styles.metricUnit}>{fcp[1]}</span>
        </div>
        <div className={styles.metricTitle}>FCP</div>
      </div>
      <div className={styles.metric}>
        <div className={styles.metricNumber}>
          {tbt[0]}
          <span className={styles.metricUnit}>{tbt[1]}</span>
        </div>
        <div className={styles.metricTitle}>TBT</div>
      </div>
      <div className={styles.metric}>
        <div className={styles.metricNumber}>
          {tti[0]}
          <span className={styles.metricUnit}>{tti[1]}</span>
        </div>
        <div className={styles.metricTitle}>TTI</div>
      </div>
    </div>
  );
};

export default Metrics;
