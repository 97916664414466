import ProfilesList from "./components/Profiles/ProfilesList/ProfilesList";
import React, { useEffect } from "react";
import Header from "./components/Header/Header";
import CreateSnapshotBar from "./components/CreateSnapshotBar/CreateSnapshotBar";
import { useGlobalState } from "./utils/globalState";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingLogin, Login } from "./components/Login/Login";
import UserFlows from "./components/UserFlows/UserFlows";
import Loading from "./components/Loading/Loading";
import { checkServerHeartbeat } from "./utils/api";

function App() {
  const { isLoading } = useGlobalState();
  const { user, isAuthenticated, isLoading: isAuthLoading } = useAuth0();

  useEffect(() => {
    checkServerHeartbeat().catch((e) => {
      console.log(e);
    });
  }, []);

  const validUser =
    user?.email &&
    (user.email.endsWith("invideo.io") ||
      user.email.endsWith("difference-engine.ai") ||
      user.email.endsWith("fnp.dev"));

  if (isAuthLoading) {
    return <LoadingLogin />;
  }

  if (
    process.env.NODE_ENV !== "development" &&
    (!isAuthenticated || !validUser)
  ) {
    return <Login />;
  }

  return (
    <div className="App">
      <Header />
      {isLoading && <Loading />}
      {!isLoading && <CreateSnapshotBar />}
      <ProfilesList />
      <UserFlows />
    </div>
  );
}

export default App;
