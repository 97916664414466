import React, { createContext, useContext, useState } from "react";

const GlobalStateContext = createContext(null);

export const GlobalStateProvider = ({ children }) => {
  const [profiles, setProfiles] = useState([]);
  const [userFlowReports, setUserFlowReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const value = {
    profiles,
    setProfiles,
    isLoading,
    setIsLoading,
    userFlowReports,
    setUserFlowReports,
  };

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalStateContext);
