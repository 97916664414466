import { createTheme } from "@mui/material/styles";

const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#0e4ba0",
    },
    secondary: {
      main: "#8610e0",
    },
  },
  fontFamily: [
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    "Segoe UI Emoji",
    "Segoe UI Symbol",
  ].join(","),
};

const theme = createTheme(themeOptions);

export default theme;
