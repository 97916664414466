import React from "react";
import { Button, IconButton, Paper } from "@mui/material";
import styles from "./Profile.module.scss";
import DownloadIcon from "@mui/icons-material/Download";
import Metrics from "./MetricsSection";

const Profile = ({ data }) => {
  const {
    lighthouseReportURL,
    harFileURL,
    profileFileURL,
    timestamp,
    testURL,
    lcp,
    fcp,
    tbt,
    tti,
  } = data;

  const timeString = new Date(timestamp).toLocaleString();

  return (
    <Paper elevation={2} className={styles.profilePaper}>
      <div className={styles.urlBar}>URL: {testURL}</div>
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div>Snapshot taken at: {timeString}</div>
          {/*<div>Snapshot initiated by {user}</div>*/}
        </div>

        <hr style={{ color: "#0e4ba0" }} />

        <div className={styles.bottomContainer}>
          <Metrics {...{ lcp, fcp, tbt, tti }} />
          <div
            className={styles.buttonsContainer}
            style={{ margin: "10px 0 0 0" }}
          >
            <a target="_blank" href={lighthouseReportURL}>
              <Button variant={"outlined"} className={styles.muiBtn}>
                View Lighthouse Report
              </Button>
            </a>

            <div
              className={styles.buttonContainer}
              style={{ margin: "8px 0 5px 0" }}
            >
              <div className={styles.bigBtnContainer}>
                <a
                  target="_blank"
                  href={`https://chromedevtools.github.io/timeline-viewer/?loadTimelineFromURL=${profileFileURL}`}
                >
                  <Button variant={"outlined"} className={styles.muiBtn}>
                    View Performance Profile
                  </Button>
                </a>
              </div>
              <a target="_blank" href={profileFileURL}>
                <IconButton color="primary">
                  <DownloadIcon />
                </IconButton>
              </a>
            </div>

            <div className={styles.buttonContainer}>
              <div className={styles.bigBtnContainer}>
                <a
                  target="_blank"
                  href={`http://www.softwareishard.com/har/viewer/?path=${harFileURL}`}
                  className={styles.muiBtn}
                >
                  <Button variant={"outlined"} className={styles.muiBtn}>
                    View Network Logs
                  </Button>
                </a>
              </div>
              <a target="_blank" href={harFileURL}>
                <IconButton color="primary">
                  <DownloadIcon />
                </IconButton>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default Profile;
