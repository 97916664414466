import React from "react";
import styles from "./Header.module.scss";

const Header = () => {
  return (
    <div>
      <h1 className={styles.heading}>IV Web Performance Monitoring</h1>
    </div>
  );
};

export default Header;
