import React, { useEffect } from "react";
import { useGlobalState } from "../../utils/globalState";
import { getUserFlowReports } from "../../utils/api";
import SingleUserFlowReport from "./SingleUserFlowReport/SingleUserFlowReport";
import styles from "./UserFlows.module.scss";

const UserFlows = () => {
  const {
    userFlowReports,
    setUserFlowReports,
    isLoading,
    setIsLoading,
  } = useGlobalState();

  useEffect(() => {
    const getData = async () => await getUserFlowReports();
    setIsLoading(true);
    getData().then((data) => {
      setUserFlowReports(data);
      setIsLoading(false);
    });
  }, []);

  if (!isLoading) {
    return (
      <div className={styles.container}>
        <h2>User Flow Reports</h2>
        {userFlowReports.length !== 0 ? (
          <>
            {userFlowReports.map((userFlowReport) => (
              <>
                <SingleUserFlowReport
                  data={userFlowReport}
                  key={userFlowReport.id}
                />
              </>
            ))}
          </>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

export default UserFlows;
