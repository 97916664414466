import React, { useEffect, useState } from "react";
import { getProfiles } from "../../../utils/api";
import Profile from "../Profile/Profile";
import LinearProgress from "@mui/material/LinearProgress";
import styles from "./ProfilesList.module.css";
import { useGlobalState } from "../../../utils/globalState";

const ProfilesList = () => {
  const { profiles, setProfiles, isLoading, setIsLoading } = useGlobalState();

  useEffect(() => {
    const getData = async () => await getProfiles();
    setIsLoading(true);
    getData().then((data) => {
      setProfiles(data);
      setIsLoading(false);
    });
  }, []);

  if (!isLoading) {
    return (
      <div className={styles.container}>
        {profiles.length !== 0 ? (
          <>
            {profiles.map((profile) => (
              <>
                <Profile data={profile} key={profile.id} />
              </>
            ))}
          </>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

export default ProfilesList;
