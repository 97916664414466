import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import styles from "./Login.module.scss";

export const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className={styles.loginWrapper}>
      <div className={styles.loginBox}>
        <div className={styles.header}>Login to your account</div>
        <div className={styles.headerTip}>
          Please use invideo.io account while logging in.
        </div>

        <div className={styles.loginBtnContainer}>
          <button
            onClick={() => loginWithRedirect()}
            className={styles.loginBtn}
          >
            Log In
          </button>
        </div>
      </div>
    </div>
  );
};

export const LoadingLogin = () => (
  <div className={styles.loginLoadingContainer}>
    <h1>Loading...</h1>
  </div>
);
