import React from "react";
import styles from "./Loading.module.scss";
import LinearProgress from "@mui/material/LinearProgress";

const Loading = () => {
  return (
    <div className={styles.loadingContainer}>
      <LinearProgress />
    </div>
  );
};

export default Loading;
