import { db } from "./firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";

const apiURL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL
    : "http://localhost:3000";

export async function getProfiles() {
  const profilesQuery = query(
    collection(db, "reports"),
    orderBy("timestamp", "desc")
  );
  const profilesSnapshot = await getDocs(profilesQuery);
  return profilesSnapshot.docs.map((doc) => doc.data());
}

export async function createSnapshot() {
  return fetch(`${apiURL}/measure`)
    .then((d) => d.json())
    .catch((e) => {
      alert(
        "There was an error creating a screenshot. Check the console for more details."
      );
      console.log(e);
    });
}

export async function getUserFlowReports() {
  const userFlowReportsQuery = query(
    collection(db, "userFlows"),
    orderBy("timestamp", "desc")
  );
  const userFlowReportsSnapshot = await getDocs(userFlowReportsQuery);
  return userFlowReportsSnapshot.docs.map((doc) => doc.data());
}

export async function checkServerHeartbeat() {
  // to wake up sleeping heroku dyno
  return fetch(`${apiURL}/heartbeat`);
}
