import React from "react";
import { Button, Paper } from "@mui/material";
import styles from "./SingleUserFlowReport.module.scss";

const SingleUserFlowReport = ({ data }) => {
  const { timestamp, userFlowName, userFlowReportURL } = data;
  const timeString = new Date(timestamp).toLocaleString();

  return (
    <Paper elevation={2} className={styles.profilePaper}>
      <div className={styles.topBar}>User Flow: {userFlowName}</div>
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div>User Flow Report taken at: {timeString}</div>
          {/*<div>Snapshot initiated by {user}</div>*/}
        </div>

        <a target="_blank" href={userFlowReportURL}>
          <Button variant={"outlined"} className={styles.muiBtn}>
            View Report
          </Button>
        </a>
      </div>
    </Paper>
  );
};

export default SingleUserFlowReport;
